<template>
  <div class="addAccount">
    <!-- 添加银行卡 -->
    <div class="topBox box">
      <van-field
        v-model="selfName"
        input-align="left"
        label="持卡人"
        readonly
        placeholder="请输入姓名"
      />
      <van-field
        v-model="accountVal"
        is-link
        readonly
        name="picker"
        label="开户银行"
        placeholder="请选择开户银行"
        @click="showAccountlist = true"
      />
      <van-popup v-model:show="showAccountlist" position="bottom">
        <van-picker
          :columns="accountList"
          @confirm="onSelect"
          @cancel="showAccountlist = false"
          :columns-field-names="customFieldName"
        />
      </van-popup>

      <van-field
        v-model="selfNum"
        input-align="left"
        label="银行卡号"
        placeholder="请输入银行卡号"
        type="number"
      >
        <!-- <template #button>
          <img @click="camera" src="./img/camera.png" alt="" />
        </template> -->
      </van-field>
    </div>
    <div class="middleBox box">
      <van-field
        v-model="selfPhone"
        input-align="left"
        type="number"
        label="预留手机号"
        placeholder="请输入预留手机号"
      />
    </div>
    <div class="bottomBox box">
      <van-field
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            v-show="canSendCode"
            @click="sendSms"
            >发送验证码</van-button
          >
          <van-button size="small" type="primary" v-show="!canSendCode"
            >{{ count }}秒</van-button
          >
        </template>
      </van-field>
    </div>
    <div class="submitBtn" @click="submit">提交</div>
    <van-action-sheet v-model:show="secondSms" title="银行卡验证">
      <div class="secondCont">
        <div class="descri">
          <p class="one">应资方要求，</p>
          <p>需要对银行卡做二次验证</p>
        </div>
        <!-- 验证码 -->
        <van-field
          v-model="smstwo"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              v-show="canSendCode1"
              @click="sendsecondSms"
              >发送验证码</van-button
            >
            <van-button v-show="!canSendCode1" size="small" type="primary"
              >{{ count1 }}秒</van-button
            >
          </template>
        </van-field>
        <div class="ensure" @click="ensure">确认</div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted } from "vue";
import { useRoute } from "vue-router";

import API from "../../server/api";
import { Toast } from "vant";

export default {
  setup() {
    const route = useRoute()
    const state = reactive({
      selfName: "",
      showAccountlist: false, // 开户银行列表
      accountVal: "",
      selfNum: "",
      selfPhone: "",
      smstwo: "",
      sms: "",
      userId: "",
      accountid: "",
      loanNo: "",
      creditNo: "",
      secondSms: false, // 二次验证码弹框
      accountList: [],
      count: 60,
      count1: 60,
      needVerify: "",
      canSendCode: true,
      canSendCode1: true,
      timer: null, // 倒计时
      timer1: null, // 倒计时
    });
    const onSelect = (value) => {
      state.accountVal = value.openBankName;
      state.accountid = value.id;
      state.showAccountlist = false;
      // console.log((state.accountid));
    };
    //显示用户默认信息(姓名、手机号)
    const userbindInfo = async () => {
      const res = await API.getuserbindInfo();
      console.log(res, "res");
      if (res.code == 200) {
        console.log(res.result);
        const { mobile, ownerName } = res.result;
        state.selfName = ownerName;
        state.selfPhone = mobile;
      }
    };
    const customFieldName = {
      text: "openBankName",
      id: "id",
    };
    // 点击提交，弹出二次验证码弹框
    const submit = () => {
      if (!state.selfName) {
        Toast.fail("请输入持卡人姓名");
        return;
      }
      if (!state.accountVal) {
        Toast.fail("请选择开户银行");
        return;
      }
      if (!state.selfNum) {
        Toast.fail("请输入银行卡号");
        return;
      }
      if (!state.selfPhone) {
        Toast.fail("请输入预留手机号");
        return;
      }
      if (!state.sms) {
        Toast.fail("请输入短信验证码");
        return;
      }
      backResult();
    };
    // 点击提交判断是否二次绑卡
    const backResult = async () => {
      const backCont = await API.ensureBind({
        userId: state.userId,
        bankAccount: state.selfNum,
        creditNo: state.creditNo,
        loanNo: state.loanNo,
        mobile: state.selfPhone,
        verifyCode: state.sms,
      });
      console.log(backCont, "hhh");
      //= =1需要二次绑卡
      if (backCont.needVerify == 1) {
        state.secondSms = true;
      } else {
        Toast.success("提交成功");
        setTimeout(() => {
           APP.Back(1)
        }, 1000);
      }
    };
    // 二次发送验证码
    const sendsecondSms = async () => {
      const smsResultTwo = await API.sendSms({
        userId: state.userId,
        bankAccount: state.selfNum,
        creditNo: state.creditNo,
        mobile: state.selfPhone,
        loanNo: state.loanNo,
      });
      console.log(smsResultTwo, "777");
      if (smsResultTwo.needVerify == 0) {
        Toast(smsResultTwo.remark);
        return false;
      } else {
        Toast(smsResultTwo.remark);
        countdowns1();
      }
    };
    // 点击确认
    const ensure = async () => {
      if (!state.smstwo) {
        Toast.fail("请输入验证码");
      } else {
        const backConts = await API.ensureBind({
          userId: state.userId,
          bankAccount: state.selfNum,
          creditNo: state.creditNo,
          loanNo: state.loanNo,
          mobile: state.selfPhone,
          verifyCode: state.smstwo,
        });
        console.log(backConts, "二次绑卡");
        if (backConts.remark == "绑卡成功") {
          Toast.success("绑卡成功");
          setTimeout(function () {
             APP.Back(1)
          }, 1000);
        } else {
          Toast.fail(backConts.remark);
        }
      }
    };
    // 发送验证码
    const sendSms = async () => {
      if (!state.selfName) {
        Toast.fail("请输入持卡人姓名");
        return;
      }
      if (!state.accountVal) {
        Toast.fail("请选择开户银行");
        return;
      }
      if (!state.selfNum) {
        Toast.fail("请输入银行卡号");
        return;
      }
      if (!state.selfPhone) {
        Toast.fail("请输入预留手机号");
        return;
      }
      const smsResult = await API.sendSms({
        userId: state.userId,
        bankAccount: state.selfNum,
        creditNo: state.creditNo,
        mobile: state.selfPhone,
        loanNo: state.loanNo,
      });
      console.log(smsResult, "短信验证码");
      if (smsResult.needVerify == 0) {
        Toast(smsResult.remark);
        return false;
      } else {
        Toast(smsResult.remark);
        countdowns();
      }
    };
    // 查看绑卡列表
    const cardList = async () => {
      const cardListData = await API.bankCard();
      if (cardListData.code == 200) {
        state.accountList = cardListData.result;
      }
      // state.accountList = []
      // for (var key in cardListData) {
      //   state.accountList.push(cardListData[key])
      // }
    };
    onMounted(() => {
      const url = route.fullPath
      const num = url.substr(-1, 1)
      console.log(num,'777666')
      if(num==1){
        state.creditNo = localStorage.getItem("creditNo");
      }else if(num==2){
        state.loanNo = localStorage.getItem("loanNos");
      }
    
      state.userId = localStorage.getItem("userId");
    
      cardList();
      userbindInfo();
    });
    // 倒计时
    const countdowns = () => {
      const TIME_COUNT = 60;
      if (!state.timer) {
        state.count = TIME_COUNT;
        state.canSendCode = false;
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--;
          } else {
            state.canSendCode = true;
            clearInterval(state.timer);
            state.timer = null;
          }
        }, 1000);
      }
    };
    const countdowns1 = () => {
      const TIME_COUNT1 = 60;
      if (!state.timer1) {
        state.count1 = TIME_COUNT1;
        state.canSendCode1 = false;
        state.timer1 = setInterval(() => {
          if (state.count1 > 0 && state.count1 <= TIME_COUNT1) {
            state.count1--;
          } else {
            state.canSendCode1 = true;
            clearInterval(state.timer1);
            state.timer1 = null;
          }
        }, 1000);
      }
    };

    return {
      ...toRefs(state),
      onSelect,
      submit,
      ensure,
      sendSms,
      countdowns,
      customFieldName,
      sendsecondSms,
      countdowns1,
    };
  },
};
</script>

<style lang="less" src="./index.less" scoped></style>
<style>
.box img {
  width: 48px;
  height: 48px;
}
</style>
